












import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    OMessageAdd: () => import('@/components/organisms/event/modules/autoMessagesModule/o-message-add.vue')
  },

  setup(){

    return {}
  }
})
